// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_url: 'https://api.harmonyrelo.com/dev/api/',
  api_key_header: 'HRN-API-Subscription-Key',
  api_key: '8d553fef604442da970b3c5e1c90e23d',
  portal_url: 'https://portal-dev.harmonyrelo.com',
  reCaptchaSiteKey: '6LdJAv0ZAAAAAOIRJSgb-XoQkQvMlQGseEKvTqGO',
  azureSubscriptionKey: '16cb2fa8-0553-4ab3-9310-9ebbc088b1d2',
  ContainerNames: {
    ArticleImages: 'articleimages',
    ArticleAttachment: 'articleattachments',
    WebDocuments: 'webdocs',
    AnnualReviewTemplates: 'templates',
    UserProfilePictures:'userporfilepictures',
    EmailAttachment:'emailscheduleattachments',
    EmergencyPlans:'emergencyplans',
    CompanyChart:'companychart'
  },
  Blobs: {
    EmergencyPlan: 'Emergency Plan Template.doc',
    AnnualReviewFAQ: 'ANNUAL REVIEW FAQ 2022.pdf',
    CovidPolicy: 'Covid-19 Compliance Code 2020.pdf',
    CodeOfConduct: 'Compliance, Code of Conduct, Harmony.pdf',
    ClientArticle: 'Relocation Services Harmony Winter 2019 2020.pdf',
  },
  FormattingStandards: {
    ShortDateFormat: 'dd MMM yyyy',
    LongDateFormat: 'dd MMM yyyy H:mm:ss'
  },
  SiteUrls: {
    AzureStorageBaseURL: 'https://sadevelopment1.blob.core.windows.net/',
    ImagePlaceholderUrl: 'placeholders/placeholder.png',
    ImagePlaceholderLargeUrl: 'placeholders/large-placeholder-image.jpg',
    NewsImagePlaceHolderUrl: 'placeholders/news-placeholder.jpg'
  },
  domain:'dev.harmonyrelo.com',
  Pricing: {
    TenderLocationLimit: 150,
    TenderEmail: 'info@harmonyrelo.com',
    TenderLocationFreightCapacity: 60,
  },
  google_api_key: 'AIzaSyBpg3SxqxWbB8tOxTli3ZgjGA_T6v9sSPY',
  b2cPolicies: {
    applicationID: 'ce28832d-2cb7-4661-a55a-bd61ae39f029',
    redirectUrl: 'https://dev.harmonyrelo.com',
    names: {
      signUpSignIn: 'b2c_1_sisu',
      forgotPassword: 'b2c_1_passwordreset',
      editProfile: 'b2c_1_edit_profile',
      signIn: 'b2c_1_signin'
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://harmonyaddev.b2clogin.com/harmonyaddev.onmicrosoft.com/b2c_1_sisu',
      },
      signIn: {
        authority: 'https://harmonyaddev.b2clogin.com/harmonyaddev.onmicrosoft.com/b2c_1_signin',
      },
      forgotPassword: {
        authority: 'https://harmonyaddev.b2clogin.com/harmonyaddev.onmicrosoft.com/b2c_1_passwordreset',
      },
      editProfile: {
        authority: 'https://harmonyaddev.b2clogin.com/harmonyaddev.onmicrosoft.com/b2c_1_editprofile'
      },
      instanceUrl: 'https://harmonyaddev.onmicrosoft.com'
    },
    authorityDomain: 'harmonyaddev.b2clogin.com'
  },
  azureEndpoints: {
    // tslint:disable-next-line:max-line-length
    resubmitMessage: 'https://prod-217.westeurope.logic.azure.com:443/workflows/e68348852a074182a20625d243e507ba/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=e4BL2CRQHcUN-oJ06U4ZPf_bLhDbM_L5yycWPlzBJQo'
  }
};
